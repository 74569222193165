<template>
  <v-card flat>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="getinformation"
      :search="search"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.id_type="{ item }">
        <span>{{ item.id_type ? item.id_type.value : null }}</span>
      </template>
      <template v-slot:item.applicant_type="{ item }">
        <v-chip small label v-if="item.applicant_type">
          {{ item.applicant_type }}
        </v-chip>
      </template>

      <template v-slot:item.occupation="{ item }">
        <span>{{ item.occupation ? item.occupation.value : null }}</span>
      </template>
      <template v-slot:item.nature_of_business="{ item }">
        <span>{{
          item.nature_of_business ? item.nature_of_business.value : null
        }}</span>
      </template>
      <template v-slot:item.nationality="{ item }">
        <span>{{
          item.nationality ? item.nationality.nationality : null
        }}</span>
      </template>
      <template v-slot:item.id_first_page="{ item }">
        <v-avatar
          v-if="item.id_first_page"
          size="36px"
          @click="handleImageViewer(item.id_first_page)"
        >
          <img
            v-if="item.id_first_page"
            alt="Avatar"
            :src="item.id_first_page"
          />
          <v-icon v-else>mdi-user</v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.address_proof="{ item }">
        <v-avatar
          v-if="item.address_proof"
          size="36px"
          @click="handleImageViewer(item.address_proof)"
        >
          <img
            v-if="item.address_proof"
            alt="Avatar"
            :src="item.address_proof"
          />
          <v-icon v-else>mdi-user</v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.id_last_page="{ item }">
        <v-avatar
          v-if="item.id_last_page"
          size="36px"
          @click="handleImageViewer(item.id_last_page)"
        >
          <img v-if="item.id_last_page" alt="Avatar" :src="item.id_last_page" />
          <v-icon v-else>mdi-user</v-icon>
        </v-avatar>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :disabled="iskycApproved"
                small
                dark
                class=""
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark left>mdi-account-plus </v-icon>
                {{ $t("add_directors") }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-divider class="p-0 m-0"></v-divider>
              <v-card-text>
                <v-form v-model="valid" ref="busApplicant">
                  <v-row>
                    <v-col class="pt-4" cols="12" md="6">
                      <label class="ma-0 pa-0">
                        {{ $t("user_type") }}
                        <span style="color: #f00">*</span></label
                      >
                      <v-autocomplete
                        :placeholder="$t('user_type_placeholder')"
                        :items="Entitycheckbox"
                        :error-messages="applicantErrors"
                        @input="$v.editedItem.applicant_type.$touch()"
                        v-model="editedItem.applicant_type"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <v-col class="pt-4" cols="12" md="6">
                      <label class="ma-0 pa-0"
                        >{{ $t("fullName")
                        }}<span
                          v-if="validationFields.includes('Full Name')"
                          style="color: #f00"
                          >*</span
                        ></label
                      >
                      <v-text-field
                        v-model="editedItem.full_name"
                        :error-messages="fnameErrors"
                        @input="$v.editedItem.full_name.$touch()"
                        dense
                        :placeholder="$t('fullName')"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0">{{ $t("Gender") }}</label>
                      <v-autocomplete
                        :placeholder="$t('gender_placeholder')"
                        :items="genderOptions"
                        v-model="editedItem.gender"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0"
                        >{{ $t("date_of_birth_business")
                        }}<span
                          v-if="validationFields.includes('DOB')"
                          style="color: #f00"
                          >*</span
                        ></label
                      >

                      <v-menu
                        ref="dobmenu"
                        v-model="dobmenu"
                        :close-on-content-click="false"
                        lazy
                        transition="scale-transition"
                        :offset-x="true"
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.dob"
                            persistent-hint
                            clearable
                            v-on="on"
                            type="date"
                            dense
                            :error-messages="DOBerrors"
                            @input="$v.editedItem.dob.$touch()"
                            @change="ValidateAge"
                            :min="minDate"
                            :max="maxDate"
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </v-menu>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0">{{
                        $t("country_of_birth_business")
                      }}</label>
                      <v-autocomplete
                        :placeholder="$t('country_of_birth_placeholder')"
                        :items="country_options"
                        item-text="name"
                        item-value="id"
                        v-model="editedItem.country_of_birth"
                        outlined
                        required
                        dense
                      ></v-autocomplete>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0"
                        >{{ $t("Nationality")
                        }}<span
                          v-if="validationFields.includes('Nationality')"
                          style="color: #f00"
                          >*</span
                        ></label
                      >
                      <v-autocomplete
                        :placeholder="$t('nationality_placeholder')"
                        dense
                        v-model="editedItem.nationality_code"
                        :items="nationality_options"
                        item-text="name"
                        item-value="id"
                        :error-messages="nationalityErrors"
                        @input="$v.editedItem.nationality_code.$touch()"
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <!-- <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0"
                        >{{ $t("Occupation")
                        }}<span
                          v-if="validationFields.includes('Designation')"
                          style="color: #f00"
                          >*</span
                        ></label
                      >
                      <v-autocomplete
                        v-model="editedItem.occupation_code"
                        :items="occupation_options"
                        item-text="name"
                        item-value="id"
                        :error-messages="designationErrors"
                        @input="$v.editedItem.occupation_code.$touch()"
                        :placeholder="$t('occupation_placeholder')"
                        dense
                        outlined
                        required
                      ></v-autocomplete>
                    </v-col> -->

                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0"
                        >{{ $t("emailAddress")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-text-field
                        v-model="editedItem.email_id"
                        :error-messages="emailErrors"
                        @input="$v.editedItem.email_id.$touch()"
                        :placeholder="$t('email_placeholder')"
                        dense
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0" cols="12" md="6">
                      <label class="ma-0 pa-0">{{ $t("phoneNumber") }}</label>
                      <vue-phone-number-input
                        default-country-code="AU"
                        @update="onChangeNumber"
                        v-model="editedItem.phone_number"
                        class=""
                      />
                      <!-- <span style="color: #ff5252; font-size: 12px">{{
                          contactNoErrors[0]
                        }}</span> -->
                    </v-col>
                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="validationFields.includes('No of Shares')"
                    >
                      <label class="ma-0 pa-0"
                        >{{ $t("share_holder_percentage")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-text-field
                        :placeholder="$t('share_holder_percentage')"
                        v-model="editedItem.no_of_shares"
                        suffix="%"
                        type="number"
                        :rules="validations.sharesRules"
                        outlined
                        required
                        hide-spin-buttons
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0" cols="12" md="6">
                      <label
                        class="ma-0 pa-0"
                        v-if="validationFields.includes('ID Number')"
                        >{{ $t("idType")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-select
                        v-if="validationFields.includes('ID Number')"
                        v-model="editedItem.id_type_code"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="validations.id_proofrules"
                        :items="idtypes_val"
                        item-text="name"
                        item-value="id"
                        outlined
                        required
                        :placeholder="$t('idtype_select_placeholder')"
                        dense
                      ></v-select>
                      <!-- <v-select
                    v-else
                    v-model="id_type_back"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="validations.id_proofrules"
                    :items="idtypes_val"
                    item-text="name"
                    item-value="id"
                    :disabled="disableValues || disableBystatus"
                    outlined
                    required
                    :placeholder="$t('idtype_select_placeholder')"
                    dense
                  ></v-select> -->
                    </v-col>
                    <!-- <v-col class="pt-0" cols="12" md="6" v-if="validationFields.includes('ID Number')">
                          <label class="ma-0 pa-0">{{ $t("idType")
                          }}<span style="color: #f00">*</span></label>
                          <v-select item-text="name" item-value="id" v-model="editedItem.id_type_code"
                            :menu-props="{ bottom: true, offsetY: true }" :rules="validations.id_proofrules"
                            :items="idtypes" outlined required :placeholder="$t('idtype_select_placeholder')"
                            dense></v-select>
  
                        </v-col> -->
                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="
                        editedItem.id_type_code != 'MYKAD' &&
                        editedItem.id_type_code != 'MyPR' &&
                        !kycStatus
                      "
                    >
                      <label
                        class="ma-0 pa-0"
                        v-if="validationFields.includes('ID Number')"
                        >{{ $t("isuueDate")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-text-field
                        v-model="editedItem.id_issue_date"
                        v-if="validationFields.includes('ID Number')"
                        :rules="validations.issuedaterules"
                        dense
                        required
                        outlined
                        type="date"
                        :max="currentDate"
                      />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="6">
                      <label
                        class="ma-0 pa-0"
                        v-if="validationFields.includes('ID Number')"
                        >{{ $t("Location")
                        }}<span
                          v-if="editedItem.id_type_code === 'Passport'"
                          style="color: #f00"
                          >*</span
                        ></label
                      >
                      <v-autocomplete
                        v-if="validationFields.includes('ID Number')"
                        v-model="editedItem.id_issue_country"
                        item-text="name"
                        item-value="id"
                        :items="country_options"
                        :rules="validations.issue_locationRules"
                        :placeholder="$t('Location')"
                        dense
                        outlined
                        required
                        :min="currentDate"
                      />
                    </v-col>

                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="
                        editedItem.id_type_code != 'MYKAD' &&
                        editedItem.id_type_code != 'MyPR' &&
                        !kycStatus
                      "
                    >
                      <label
                        class="ma-0 pa-0"
                        v-if="validationFields.includes('ID Number')"
                        >{{ $t("expiryDate")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-text-field
                        v-model="editedItem.id_expiry_date"
                        v-if="validationFields.includes('ID Number')"
                        :rules="validations.expDateRules"
                        dense
                        outlined
                        required
                        type="date"
                        :min="currentDate"
                      />
                    </v-col>

                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="validationFields.includes('ID Number')"
                    >
                      <label class="ma-0 pa-0"
                        >{{ $t("idNumber")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-text-field
                        v-model="editedItem.id_number"
                        :rules="validations.id_numberRules"
                        :placeholder="$t('id_number_placeholder')"
                        outlined
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="pt-0">
                      <label class="ma-0 pa-0"
                        >{{ $t("DirectorAddressProof")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-file-input
                        v-model="editedItem.address_proof"
                        :rules="validations.firstpage_rules"
                        dense
                        accept="image/png, image/jpeg, image/bmp"
                        :placeholder="$t('idtype_first_page_placeholder')"
                        outlined
                        ref="addressProof"
                        required
                        prepend-icon=""
                        @change="
                          genrateURL(
                            'addressProofUrl',
                            editedItem.address_proof,
                            'address_proof'
                          )
                        "
                      >
                        <template v-slot:append>
                          <v-icon
                            aria-hidden="false"
                            class="color-primary"
                            @click="openAddressProof"
                          >
                            mdi-cloud-upload
                          </v-icon>
                        </template>

                        <template v-slot:append-outer>
                          <v-img
                            v-if="addressProofUrl"
                            class="ml-1"
                            max-height="40"
                            max-width="50"
                            :src="addressProofUrl"
                            @click="handleImageViewer(addressProofUrl)"
                          />
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="validationFields.includes('ID Number')"
                    >
                      <label class="ma-0 pa-0"
                        >{{ $t("IDFirstPage")
                        }}<span style="color: #f00">*</span></label
                      >
                      <v-file-input
                        v-model="editedItem.ID_First_Page"
                        :rules="validations.firstpage_rules"
                        dense
                        accept="image/png, image/jpeg, image/bmp"
                        :placeholder="$t('idtype_first_page_placeholder')"
                        outlined
                        ref="idfirstfile"
                        @change="
                          genrateURL(
                            'frontPageUrl',
                            editedItem.ID_First_Page,
                            'ID_First_Page'
                          )
                        "
                        required
                        prepend-icon=""
                      >
                        <template v-slot:append>
                          <v-icon
                            aria-hidden="false"
                            class="color-primary"
                            @click="openIdfirst"
                          >
                            mdi-cloud-upload
                          </v-icon>
                        </template>

                        <template v-slot:append-outer>
                          <v-img
                            v-if="frontPageUrl"
                            class="ml-1"
                            max-height="40"
                            max-width="50"
                            :src="frontPageUrl"
                            @click="handleImageViewer(frontPageUrl)"
                          />
                        </template>
                      </v-file-input>
                    </v-col>

                    <v-col
                      class="pt-0"
                      cols="12"
                      md="6"
                      v-if="validationFields.includes('ID Number')"
                    >
                      <label class="ma-0 pa-0">{{ $t("IDLastPage") }}</label>
                      <v-file-input
                        v-model="editedItem.ID_Last_Page"
                        dense
                        accept="image/png, image/jpeg, image/bmp"
                        :placeholder="$t('idtype_last_page_placeholder')"
                        outlined
                        @change="
                          genrateURL(
                            'backPageUrl',
                            editedItem.ID_Last_Page,
                            'ID_Last_Page'
                          )
                        "
                        ref="idlastfile"
                        required
                        prepend-icon=""
                      >
                        <template v-slot:append>
                          <v-icon
                            aria-hidden="false"
                            class="color-primary"
                            @click="openIdlast"
                          >
                            mdi-cloud-upload
                          </v-icon>
                        </template>

                        <template v-slot:append-outer>
                          <v-img
                            v-if="backPageUrl"
                            class="ml-1"
                            max-height="40"
                            max-width="50"
                            :src="backPageUrl"
                            @click="handleImageViewer(backPageUrl)"
                          />
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider class="p-o m-0"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  outlined
                  @click="close"
                  v-if="!editedItem.biz_user_id"
                >
                  {{ $t("cancel_label") }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="onClickNext()"
                  v-if="!editedItem.biz_user_id"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  color="primary"
                  outlined
                  @click="close"
                  v-if="editedItem.biz_user_id"
                >
                  {{ $t("cancel_label") }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="update()"
                  v-if="editedItem.biz_user_id"
                >
                  {{ $t("update_label") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">
                {{ $t("want_to_delete_info") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined small color="primary" @click="closeDelete">
                  {{ $t("cancel_label") }}</v-btn
                >
                <v-btn
                  class="elevation-0"
                  color="primary"
                  small
                  @click="deleteItemConfirm"
                >
                  {{ $t("okay") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-menu :offset-x="true" open-on-hover v-if="is_shareholder">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-if="!iskycApproved"
                class="v-custome-btn ms-2"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon dark left>mdi-account-plus </v-icon
                >{{ $t("add_shareholder") }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="(showindDialog = true), (selectedINDData = null)"
              >
                <v-list-item-title
                  ><v-icon class="mb-1 me-2"> mdi-account-tie</v-icon>
                  {{ $t("individual") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="(showBusDialog = true), (selectedBUSData = null)"
              >
                <v-list-item-title
                  ><v-icon class="mb-1 me-2">mdi-briefcase-account</v-icon>
                  {{ $t("business") }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-col lg="6" sm="12" class="">
            <v-text-field
              v-model="search"
              append-icon="mdi-filter"
              :placeholder="$t('search_to_filter')"
              class=" "
              color="primary"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.applicant_type != 'Representative'"
          small
          :disabled="iskycApproved"
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="item.applicant_type != 'Representative'"
          small
        :disabled="iskycApproved"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="showindDialog" width="700px">
      <v-col cols="12" class="p-0">
        <template v-if="showindDialog">
          <shareholder-ind
            @closeDialog="closeDialog"
            @getapplicant="getapplicant"
            :selectedINDData="selectedINDData"
            :showindDialog="showindDialog"
          />
        </template>
      </v-col>
    </v-dialog>
    <v-dialog v-model="showBusDialog" width="700px">
      <v-col cols="12" class="p-0">
        <template v-if="showBusDialog">
          <shareholder-bus
            @closeBusDialog="closeBusDialog"
            @getapplicant="getapplicant"
            :selectedBUSData="selectedBUSData"
            :showBusDialog="showBusDialog"
          />
        </template> </v-col
    ></v-dialog>

    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '435',
        PicWidth: 600,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { i18n as $ } from "../../../locale/index";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import { findDatePrevious } from "../../../utils/helper";
import { alpha } from "../../../utils/validate";
// import { BusUserTypes } from "../../../utils/BusDocuments";
import validatemixin from "@/mixins/Busvalidate.mixin";
import axios from "axios";
import imageViewer from "../../tools/imageViewer.vue";
import handleImageUpload from "../../../mixins/compressImage.mixin";
// import { issueDate, expireDate } from "../../../utils/validate";
import ShareholderInd from "./ShareholderInd.vue";
import ShareholderBus from "./ShareholderBus.vue";
export default {
  mixins: [validatemixin, handleImageUpload],
  data: () => ({
    id_type: "",
    dialog: false,
    showindDialog: false,
    showBusDialog: false,
    selectedBUSData: null,
    selectedINDData: null,
    search: "",
    valid: true,
    currentDate: new Date().toJSON().split("T")[0],
    // buscheckboxes: BusUserTypes(),
    Entitycheckbox: ["Board of directors"],
    validationFields: [],
    backPageUrl: null,
    frontPageUrl: null,
    dialogDelete: false,
    dobmenu: false,
    kycStatus: null,
    disableBystatus: null,
    id_type_back: null,
    issue_location: null,
    maxDate: "",
    minDate: "",
    isDobError: false,
    expriydate: false,
    isValidNumber: false,
    country_options: [],
    relation_options: [],
    idtypes: [],
    idtypes_val: [],
    idtype_objs: [],
    nationality_options: [],
    occupation_options: [],
    occupation_objs: [],
    genderOptions: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
    ],
    isSubmitted: false,
    isUploading: false,
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    countrySelected: {},
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "biz_user_id",
      },
      {
        text: "Name/Company",
        align: "start",
        sortable: false,
        value: "full_name",
      },
      { text: "ID type", value: "id_type", sortable: false },
      { text: "ID number", value: "id_number", sortable: false },
      { text: "Designation", value: "applicant_type", sortable: false },
      { text: "Nationality", value: "nationality", sortable: false },
      { text: "DOB/DOI", value: "dob", sortable: false, width: "180px" },
      { text: "Email", value: "email_id", sortable: false },
      // {
      //   text: "Contact Number",
      //   value: "phone_number",
      //   sortable: false,
      //   width: "180px",
      // },
      {
        text: "Shareholding (%)",
        value: "percentage_of_shares",
        sortable: false,
      },
      {
        text: "Nature of Business",
        value: "nature_of_business",
        sortable: false,
      },
      // {
      //   text: "Occupation",
      //   value: "occupation",
      //   sortable: false,
      // },
      { text: "First page", value: "id_first_page", sortable: false },
      { text: "Last page", value: "id_last_page", sortable: false },
      { text: "Address Proof", value: "address_proof", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    getinformation: [],
    editedIndex: -1,
    biz_user_id: "",
    imageViewerUrl: "",
    isImageViewer: false,
    addressProofUrl: null,
    userId: null,
    editedItem: {
      applicant_type: "",
      full_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: null,
      email_id: "",
      phone_number: "",
      nationality_code: "",
      occupation_code: "",
      country_of_birth: "",
      // applicant_country_of_birth: "",
      id_type_code: null,
      id_number: null,
      ID_First_Page: null,
      address_proof: null,
      ID_Last_Page: null,
      no_of_shares: null,
      id_issue_date: null,
      id_expiry_date: null,
      id_issue_country: "AUS",
      biz_user_id: null,
    },
    deleteId: "",
    defaultItem: {
      applicant_type: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: null,
      email_id: "",
      phone_number: "",
      nationality_code: "",
      occupation_code: "",
      country_of_birth: "",
      // applicant_country_of_birth: "",
      id_type_code: null,
      id_number: null,
      ID_First_Page: null,
      address_proof: null,
      ID_Last_Page: null,
      no_of_shares: null,
      id_issue_date: null,
      id_expiry_date: null,
      id_issue_country: "",
    },
    validations: {
      selfy_rules: [(v) => !!v || $.t("file_select_placeholder")],
      firstpage_rules: [(v) => !!v || $.t("file_select_placeholder")],
      issuedaterules: [(v) => !!v || $.t("issueDateMsg1")],
      issue_locationRules: [],
      expDateRules: [(v) => !!v || $.t("expiryDateMsg1")],
      id_proofrules: [(v) => !!v || $.t("idtypeMsg1")],
      id_numberRules: [(v) => !!v || $.t("idnumberMsg1")],
      interviewData: [(v) => !!v || $.t("interviewDate")],
      interviewTime: [(v) => !!v || $.t("interviewTime")],
      sharesRules: [
        (v) => !!v || $.t("no_ofshares"),
        (v) => (v > 25 && v < 100) || $.t("no_ofsharesmsg"),
      ],
    },
    is_shareholder: true,
  }),
  name: "BasicDetails",
  components: {
    VuePhoneNumberInput,
    imageViewer,
    ShareholderInd,
    ShareholderBus,
  },
  props: {
    selctedEntity: {
      type: String,
    },
    iskycApproved: {
      type: Boolean,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Applicant Info" : "Edit Applicant Info";
    },
    ...mapGetters("Transactional", [
      "realtion_list",
      "nationality_list",
      "occupation_list",
    ]),
    ...mapGetters("Authentication", ["country_list"]),
    applicantErrors() {
      const errors = [];
      if (!this.$v.editedItem.applicant_type.$dirty) return errors;
      !this.$v.editedItem.applicant_type.required &&
        errors.push(this.$t("usertype_req"));
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.full_name.$dirty) return errors;
      !this.$v.editedItem.full_name.required &&
        errors.push(this.$t("full_name"));
      !this.$v.editedItem.full_name.alpha &&
        errors.push(this.$t("invalid_full_name"));
      return errors;
    },

    designationErrors() {
      const errors = [];
      if (!this.$v.editedItem.occupation_code.$dirty) return errors;
      !this.$v.editedItem.occupation_code.required &&
        errors.push(this.$t("select_occupation"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email_id.$dirty) return errors;
      !this.$v.editedItem.email_id.required &&
        errors.push(this.$t("email_errors"));
      !this.$v.editedItem.email_id.email &&
        errors.push(this.$t("invalid_email"));
      return errors;
    },

    nationalityErrors() {
      const errors = [];
      if (!this.$v.editedItem.nationality_code.$dirty) return errors;
      !this.$v.editedItem.nationality_code.required &&
        errors.push(this.$t("nationality"));
      return errors;
    },

    DOBerrors() {
      const errors = [];
      if (!this.$v.editedItem.dob.$dirty) return errors;
      !this.$v.editedItem.dob.required && errors.push(this.$t("select_dob"));
      if (this.isDobError) {
        errors.push(this.$t("invalid_dob"));
      }
      return errors;
    },
  },

  watch: {
    "editedItem.applicant_type"(applicant_type) {
      console.log("applicant_type", applicant_type);
      this.validationFields = [...this.getRequired(applicant_type)];
      console.log("applicant_type:", this.getRequired(applicant_type));
    },
    // selctedEntity: {
    //   handler(newval) {
    //     console.log("treatEntityName", newval);
    //     this.EntityName = newval;
    //     this.returnBusCheckbox(newval, this.buscheckboxes);
    //     console.log(" this.EntityName ", newval);
    //   },
    // },
    "editedItem.id_type"(val) {
      this.idtype_objs.map((i) => {
        if (val === i.parameter_value) {
          this.id_type_code = i.parameter_code;
        }
      });
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    id_type(val) {
      this.idtype_objs.map((i) => {
        if (val === i.value) {
          this.id_type_code = i.id;
        }
      });
      if (val == "MYKAD" || val == "MyPR") {
        this.editedItem.id_issue_country = "AUS";
        this.disableIssueLoc = true;
      } else {
        // this.user_details.issue_location = "";
        this.disableIssueLoc = false;
      }
      if (val === "Passport") {
        this.validations.issue_locationRules = [
          (v) => !!v || $.t("issue_location_placeholder"),
        ];
      } else {
        this.validations.issue_locationRules = [];
      }
    },

    "editedItem.issue_location"(val) {
      this.country_objs.map((i) => {
        if (val === i.name) {
          this.issue_location_code = i.iso3;
        }
      });
    },

    "editedItem.id_issue_date"(val) {
      if (!val < new Date().toJSON().split("T")[0]) {
        this.validations.issuedaterules.push($.t("issue_date_valid"));
      } else {
        this.validations.issuedaterules = [(v) => !!v || $.t("issueDateMsg1")];
      }
    },
    "editedItem.id_expiry_date"(val) {
      if (!val > new Date().toJSON().split("T")[0]) {
        this.validations.expDateRules.push($.t("expiry_date_issue_validate"));
      } else {
        this.validations.expDateRules = [(v) => !!v || $.t("expiryDateMsg1")];
      }
    },

    countrySelected(newVal) {
      console.log("Selected country", newVal);
      if (Object.keys(newVal).length > 0) {
        this.editedItem.country_of_birth = newVal.name;
        this.editedItem.applicant_country_code = newVal.iso3;
      }
    },
  },

  created() {
    // this.initialize();
  },
  validations() {
    return {
      editedItem: {
        applicant_type: {
          required,
        },
        full_name: {
          required: requiredIf(function () {
            if (this.validationFields.includes("Full Name")) return true;
            else return false;
          }),
          alpha,
        },
        nationality_code: {
          required: requiredIf(function () {
            if (this.validationFields.includes("Nationality")) return true;
            else return false;
          }),
        },
        occupation_code: {
          required: requiredIf(function () {
            if (this.validationFields.includes("Designation")) return true;
            else return false;
          }),
        },
        email_id: {
          required,
          email,
        },

        dob: {
          required: requiredIf(function () {
            if (this.validationFields.includes("DOB")) return true;
            else return false;
          }),
        },
      },
    };
  },
  mounted() {
    // this.returnBusCheckbox(this.selctedEntity, this.buscheckboxes);
    this.getRelationship();
    this.getCountryList();
    // this.getOccupation();
    this.getNationalityList();
    this.getidtypes();
    this.getapplicant();

    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },

  methods: {
    genrateURL(keyField, value, assignKey) {
      if (!keyField) return;
      if (!value) {
        if (assignKey == "ID_First_Page") {
          this.frontPageUrl = null;
        } else if (assignKey === "ID_Last_Page") {
          this.backPageUrl = null;
        }
        this.editedItem[`${assignKey}`] = null;
      } else {
        if (assignKey == "ID_First_Page") {
          this.frontPageUrl = URL.createObjectURL(value);
        } else if (assignKey === "ID_Last_Page") {
          this.backPageUrl = URL.createObjectURL(value);
        } else if (assignKey === "address_proof") {
          this.addressProofUrl = URL.createObjectURL(value);
        }
        this.editedItem[`${assignKey}`] = value;
      }
    },
    closeDialog(val) {
      console.log(val);
      this.showindDialog = val;
    },
    closeBusDialog(val) {
      console.log(val);
      this.showBusDialog = val;
      this.selectedBUSData = null;
    },

    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },

    getapplicant() {
      this.isLoading = true;
      axios({
        url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/all-applicant-information`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          this.isLoading = false;

          if (response.status === 200) {
            this.getinformation = response.data.data.reverse();
            // const getinform = response.data.data[0];
          }

          console.log("getdata", this.getinformation);
        })

        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },

    async getOccupation() {
      await this.$store.dispatch("Transactional/getOccupation");
      console.log("Occupation", this.occupation_list.occupation.data);
      // console.log(`responseData: ${JSON.stringify(this.occupation_list.occupation.data[0])}`);
      if (Array.isArray(this.occupation_list.occupation.data)) {
        this.occupation_options =
          await this.occupation_list.occupation.data.map((i) => {
            return {
              name: i.value,
              id: i.value_code,
            };
          });

        this.occupation_list.occupation.data.map((i) => {
          this.occupation_objs.push(i);
        });

        console.log("this", this.editedItem.occupation_code);
      }
    },
    async onFileChange(e) {
      console.log(e);
      const files = e.target.files || e.dataTransfer.files;

      this.IDfileName = files[0].name;
      const idpreviewImage = await this.getBase64(files[0]);
      this.idpreviewImage = idpreviewImage;
      const extractBase64 = idpreviewImage.split(",").reverse()[0];
      this.user_details.fileUpload = extractBase64;

      let fileData = new FormData();
      fileData.append("document", files[0]);
      console.log(fileData.document);
      try {
        console.log("inside of the try ");
        const responseData = await this.$store.dispatch(
          "Authentication/ekycDocSubmit", //flag for upload
          fileData
        );
        if (responseData.status === 200) {
          console.log("test uploaded");
        } else {
          this.errorMessage = responseData.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
        }
      } catch (error) {
        this.dismissCountDownError = this.dismissSecs;
        this.loader = false;
        this.errorMessage = "Server API is not working Please wait sometime";
      }
    },
    async fileUpload(fileObject, biz_user_id, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const token = sessionStorage.getItem("access_token");
      let fileData = new FormData();
      fileData.append("document", compressdata);
      try {
        const responseData = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/applicant-information/upload-docs?biz_user_id=${biz_user_id}&doc_type=${doc_type}`,
          data: fileData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          // params: { doc_type },
        });

        if (responseData && responseData.data.status_code == 200) {
          console.log("responseData", responseData);
          this.getapplicant();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fileaddressProofUpload(fileObject, biz_user_id, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const token = sessionStorage.getItem("access_token");
      let fileData = new FormData();
      fileData.append("document", compressdata);
      try {
        const responseData = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/upload-address-proof?biz_user_id=${biz_user_id}&doc_type=${doc_type}`,
          data: fileData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (responseData && responseData.data.status_code == 200) {
          console.log("responseData", responseData);
          this.loader = false;
          this.getapplicant();
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async getidtypes() {
      let response = await this.$store.dispatch("Transactional/getIDtypes");
      response.data.data.map((idtype) => {
        this.idtypes.push(idtype.value);
        this.idtypes_val.push({
          name: idtype.value,
          id: idtype.value_code,
        });
        this.idtype_objs.push(idtype);
      });
      console.log("typein", this.idtypes);
    },

    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    openIdlast() {
      this.$refs.idlastfile.$el.querySelector("input").click();
    },
    openAddressProof() {
      this.$refs.addressProof.$el.querySelector("input").click();
    },
    openIdfirst() {
      this.$refs.idfirstfile.$el.querySelector("input").click();
    },
    ValidateAge(value) {
      if (!value) this.isDobError = false;
      else this.isDobError = !(value <= this.maxDate && value >= this.minDate);
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },

    async getCountryList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        // this.onChangeCounty('MYS');
      });
      console.log("country_options", this.country_options);
    },
    async getRelationship() {
      await this.$store.dispatch("Transactional/getRelationship");
      if (Array.isArray(this.realtion_list.response.data)) {
        this.relation_options = await this.realtion_list.response.data.map(
          (i) => i.value
        );
      }
    },

    async getNationalityList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("nationality,", responseData);
      responseData.data.map((i) => {
        this.nationality_options.push({
          name: i.nationality,
          id: i.iso_alpha3,
        });
      });
    },

    editItem(item) {
      console.log("table data", item);
      if (item.applicant_type == "Board of directors") {
        this.editedItem = { ...item };
        this.editedItem.id_type_code =
          item && item.id_type && item.id_type.value_code;
        this.editedItem.country_of_birth =
          item && item.country_of_birth && item.country_of_birth.iso_alpha3;
        this.editedItem.nationality_code =
          item && item.nationality && item.nationality.iso_alpha3;
        this.editedItem.ID_First_Page = item.id_first_page
          ? {
              name: item.id_first_page?.split("/").pop(),
              value: item.id_first_page,
            }
          : null;
        this.editedItem.ID_Last_Page = item.id_last_page
          ? {
              name: item.id_last_page?.split("/").pop(),
              value: item.id_last_page,
            }
          : null;
        this.editedItem.address_proof = item.address_proof
          ? {
              name: item.address_proof?.split("/").pop(),
              value: item.address_proof,
            }
          : null;
        this.backPageUrl = item.id_last_page ? item.id_last_page : null;
        this.frontPageUrl = item.id_first_page ? item.id_first_page : null;
        this.addressProofUrl = item.address_proof ? item.address_proof : null;
        this.dialog = true;
      } else if (item.user_type == "BUS") {
        this.showBusDialog = true;
        this.selectedBUSData = { ...item };
      } else if (item.user_type == "IND") {
        this.showindDialog = true;
        this.selectedINDData = { ...item };
      }
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteId = item.biz_user_id;
    },

    deleteItemConfirm() {
      this.loader = true;

      axios
        .delete(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/delete-applicant-information?biz_user_id=${this.deleteId}`,

          {
            headers: {
              ContentType: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            this.loader = false;
          }, 400);
          this.response = response;
          if (response && response.data.status_code == 200) {
            this.alertMsg.status = true;
            this.getapplicant();
            this.alertMsg.text = response.data.message;
            this.alertMsg.type = "success";
            this.alertMsg.color = "green";
          } else {
            this.alertMsg.status = true;
            this.alertMsg.text = `Failed: ${response.data.message}`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "warning";
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.alertMsg.status = true;
          this.alertMsg.text = `Process Failed: Please try again sometime.`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "error";
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.deleteId = "";
      });
    },

    onClickNext() {
      console.log(`editedItem: ${JSON.stringify(this.editedItem)}`);
      this.$v.$touch();
      if (this.$v.$invalid && this.$refs.busApplicant.validate()) {
        return;
      }
      if (this.$refs.busApplicant.validate()) {
        this.save();
      }
    },

    onClickBack() {
      this.$emit("onClickBack");
    },

    async save() {
      this.loader = true;
      if (this.editedIndex > -1) {
        Object.assign(this.getinformation[this.editedIndex], this.editedItem);
      }
      try {
        const responseData = await axios.post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-applicant-information`,
          this.editedItem,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );

        console.log("responseData", responseData);
        if (
          responseData &&
          responseData.data &&
          responseData.data.status_code === 200
        ) {
          this.userId = responseData.data.data[0].biz_user_id;
          console.log(" this.userId", this.userId);
          console.log("ll", responseData);
          this.alertMsg.status = true;
          this.alertMsg.text = responseData.data.message;
          this.alertMsg.type = "success";
          this.alertMsg.color = "green";
          this.fileaddressProofUpload(
            this.editedItem.address_proof,
            responseData.data.data[0].biz_user_id,
            "ID_First_Page"
          );
          this.fileUpload(
            this.editedItem.ID_First_Page,
            responseData.data.data[0].biz_user_id,
            "ID_First_Page"
          );
          // let ID_Last_Page = new FormData();
          // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
          this.fileUpload(
            this.editedItem.ID_Last_Page,
            responseData.data.data[0].biz_user_id,
            "ID_Last_Page"
          );
          this.getapplicant();
        } else {
          this.alertMsg.status = true;
          this.alertMsg.text = `Creation Failed: ${responseData.data.message}`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "warning";
          // alert("iam king")
        }
      } catch (error) {
        this.alertMsg.status = true;
        this.alertMsg.text = `Creation Failed: please try again sometime`;
        this.alertMsg.type = "error";
        this.alertMsg.color = "error";
        // alert("jj")
      }
      this.close();
    },

    update() {
      this.$v.$touch();
      if (this.$v.$invalid && this.$refs.busApplicant.validate()) {
        return;
      }
      if (this.$refs.busApplicant.validate()) {
        this.updateData();
      }
    },

    updateData() {
      this.loader = true;
      if (this.editedIndex < -1) {
        Object.assign(this.getinformation[this.editedIndex], this.editedItem);
      } else {
        axios
          .put(
            `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/update-applicant-information?biz_user_id=${this.editedItem.biz_user_id}`,
            this.editedItem,

            {
              headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access_token"
                )}`,
                accept: "application/json",
              },
            }
          )
          .then((responseData) => {
            console.log(`responseData: ${JSON.stringify(responseData)}`);
            if (responseData && responseData.data.status_code == 200) {
              this.alertMsg.status = true;
              this.alertMsg.text = responseData.data.message;
              this.alertMsg.type = "success";
              this.alertMsg.color = "green";
              this.fileaddressProofUpload(
                this.editedItem.address_proof,
                responseData.data.data[0].biz_user_id,
                "ID_First_Page"
              );
              this.fileUpload(
                this.editedItem.ID_First_Page,
                responseData.data.data[0].biz_user_id,
                "ID_First_Page"
              );
              // let ID_Last_Page = new FormData();
              // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
              this.fileUpload(
                this.editedItem.ID_Last_Page,
                responseData.data.data[0].biz_user_id,
                "ID_Last_Page"
              );

              this.getapplicant();
              this.close();
            } else {
              this.alertMsg.status = true;
              this.alertMsg.text = `Update Failed: ${responseData.data.message}`;
              this.alertMsg.type = "error";
              this.alertMsg.color = "warning";
            }
            console.log("respose data", responseData);
          })
          .catch((error) => {
            console.log(error.response);
            this.alertMsg.status = true;
            this.alertMsg.text = `Update Failed: please try again sometime`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "error";
          });
        setTimeout(() => {
          this.loader = false;
        }, 300);
      }
    },
  },
};
</script>
<style>
.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
