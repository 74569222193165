export default {
  data() {
    return {
      Reqfields: {
        Representative: [
          "Full Name",
          "ID Number",
          "Designation",
          "Nationality",
        ],
        "Senior Management": ["Full Name", "Designation", "Nationality"],
        Shareholder: [
          "Full Name",
          "ID Number",
          "Designation",
          "Nationality",
          "No of Shares",
        ],
        "Board of directors": ["Full Name", "ID Number", "DOB", "Nationality"],
        "List of Board of partners": [
          "Full Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        Settlor: ["First Name", "Last Name", "ID Number", "DOB", "Nationality"],
        "Trustee(s)": [
          "First Name",
          "Last Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        Beneficiaries: [
          "First Name",
          "Last Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        "other authorized person": [
          "First Name",
          "Last Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        Protector: ["Full Name", "ID Number", "DOB", "Nationality"],
        Owner: ["First Name", "Last Name", "ID Number", "DOB", "Nationality"],
        "List of Office bearer": [
          "Full Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        "Chairman or president": [
          "Full Name",
          "ID Number",
          "DOB",
          "Nationality",
        ],
        Treasurer: ["Full Name", "ID Number", "DOB", "Nationality"],
        Secretary: ["Full Name", "ID Number", "DOB", "Nationality"],
      },
    };
  },
  methods: {
    getRequired(val) {
      let keys = Object.keys(this.Reqfields);
      let requiredOtp = [];
      keys.map((ele) => {
        if (val.includes(ele)) {
          requiredOtp = [...requiredOtp, ...this.Reqfields[`${ele}`]];
        }
      });
      return requiredOtp.filter(
        (item, index) => requiredOtp.indexOf(item) === index
      );
    },
  },
};
