// Function to show list of individual documnets in Documents Page
export function IndbusinessDocs() {
  return [
    {
      name: "Registration of Company Extract (ASIC)",
      code: "roce",
      value_code: "1",
      value: "2",
      required: true,
    },
    {
      name: "Certificate of Registration",
      code: "cor",
      value_code: "2",
      required: true,
    },
    {
      name: "Proof of Address of Business (e.g. Utility Bill in name of the Company within the last 6 months)",
      code: "poa",
      value_code: "3",
      value: "2",
      required: true,
    },
    {
      name: "Current AML/CTF Program (For MSBs, Financial Institutions, High Risk Merchants)",
      code: "aml_ctf",
      value_code: "4",
      value: "2",
      required: false,
    },
    {
      name: "QQPay AML/KYC Questionnaire on page 4 (For MSBs, Financial Institutions, High Risk Merchants",
      code: "aml_kyc",
      value_code: "5",
      value: "2",
      required: false,
    },
  ];
}

// Function to show list of user types and requirement of Authorization page
export function entityTypes() {
  return {
    entityTypes: [
      {
        value_code: "1",
        value: "Private Company Limited By Shares (Ltd)",
        id: 1775,
      },
      {
        value_code: "2",
        value: "Limited Liability Company (Llc)",
        id: 1776,
      },
      {
        value_code: "3",
        value: "Public Limited Company (Plc)",
        id: 1777,
      },
      {
        value_code: "4",
        value: "Limited (Ltd)",
        id: 1778,
      },
      {
        value_code: "5",
        value: "Partnership (Ptr)",
        id: 1779,
      },
      {
        value_code: "6",
        value: "Sole-Proprietorship (Spr)",
        id: 1780,
      },
      {
        value_code: "7",
        value: "Organ Of State",
        id: 1781,
      },
      {
        value_code: "8",
        value: "Public Company Limited By Guarantee",
        id: 1782,
      },
      {
        value_code: "9",
        value: "Public Company Limited By Shares",
        id: 1783,
      },
      {
        value_code: "10",
        value: "Association",
        id: 1784,
      },
      {
        value_code: "11",
        value: "Charity",
        id: 1785,
      },
      {
        value_code: "12",
        value: "Club",
        id: 1786,
      },
      {
        value_code: "13",
        value: "Revocable Trust",
        id: 1787,
      },
      {
        value_code: "14",
        value: "Irrevocable Trust",
        id: 1788,
      },
      {
        value_code: "15",
        value: "Others (Please Specify)",
        id: 1789,
      },
    ],
  };
}
