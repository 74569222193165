import request from "@/Axios/request";
const baseUrl = `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business`;

export default {
  getentityInfo(customer_id) {
    return request({
      url: `${baseUrl}/get-entity-information?customer_id=${customer_id}`,
      method: "get",
    });
  },
  getapplicantInfo(customer_id) {
    return request({
      url: `${baseUrl}/get-applicant-information-data?customer_id=${customer_id}`,
      method: "get",
    });
  },
  getallApplicantinfo(customer_id) {
    return request({
      url: `${baseUrl}/all-applicant-information?customer_id=${customer_id}`,
      method: "get",
    });
  },
  getapplicantcombinfo(customer_id) {
    return request({
      url: `${baseUrl}/get-entity-information?customer_id=${customer_id}`,
      method: "get",
    });
  },
  getuploaddocs() {
    return request({
      url: `${baseUrl}/get-upload-documents`,
      method: "get",
    });
  },
  uploadDocs(value, value_code, data) {
    return request({
      url: `${baseUrl}/upload-documents?type=${value}&doc_type=${value_code}`,
      method: "POST",
      data,
    });
  },
  DeleteBusdocs(params) {
    return request({
      url: `${baseUrl}/delete-doc`,
      method: "PUT",
      params: params,
      data: params,
    });
  },
  updateEntityDetails(data, params) {
    return request({
      url: `${baseUrl}/update-entity-information`,
      method: "PUT",
      params: params,
      data: data,
    });
  },
  updateKYCDetails(params, data) {
    return request({
      url: `${baseUrl}/update-applicant-information`,
      method: "PUT",
      params: params,
      data: data,
    });
  },
  updateIndAuthorization(id, data) {
    return request({
      url: `${baseUrl}/update-authorization-ind-person-information?biz_user_id=${id}`,
      method: "PUT",
      data,
    });
  },
  updateBusAuthorization(id, data) {
    return request({
      url: `${baseUrl}/update-authorization-bus-person-information?biz_user_id=${id}`,
      method: "PUT",
      data,
    });
  },

  //-------------------------Create Business Customer-------------------------------//
  addEntityinformation(data, params) {
    return request({
      url: `${baseUrl}/entity-information`,
      method: "POST",
      data,
      params: params,
    });
  },
  updateEntityinformation(data, params) {
    return request({
      url: `${baseUrl}/update-entity-information`,
      method: "PUT",
      data,
      params: params,
    });
  },
  getEntityinformation(params) {
    return request({
      url: `${baseUrl}/get-entity-information`,
      method: "GET",
      params: params,
    });
  },
  getOthersData(params) {
    return request({
      url: `${baseUrl}/get-other-data`,
      method: "GET",
      params: params,
    });
  },
  addOtherData(data, params) {
    return request({
      url: `${baseUrl}/add-other-data`,
      method: "POST",
      data,
      params: params,
    });
  },
  getUploadBusDocs() {
    return request({
      url: `${baseUrl}/get-upload-documents`,
      method: "GET",
    });
  },
  UploadBusDocs(data, params) {
    return request({
      url: `${baseUrl}/upload-documents`,
      method: "POST",
      data,
      params: params,
    });
  },
  getDeclarationData() {
    return request({
      url: `${baseUrl}/get-business-declaration`,
      method: "GET",
    });
  },
  addDeclarationData(data, params) {
    return request({
      url: `${baseUrl}/add-business-declaration`,
      method: "POST",
      data,
      params: params,
    });
  },
  getApplicantInfo(params) {
    return request({
      url: `${baseUrl}/get-applicant-information-data`,
      method: "GET",
      params: params,
    });
  },
  addApplicantInfo(data, params) {
    return request({
      url: `${baseUrl}/add-applicant-information`,
      method: "POST",
      data,
      params: params,
    });
  },
  updateApplicantInfo(data, params) {
    return request({
      url: `${baseUrl}/update-applicant-information`,
      method: "PUT",
      data,
      params: params,
    });
  },
  UploadApplicantFiles(data, params) {
    return request({
      url: `${baseUrl}/applicant-information/upload-docs`,
      method: "POST",
      data,
      params: params,
    });
  },
  deleteApplicantInfo(params) {
    return request({
      url: `${baseUrl}/delete-applicant-information`,
      method: "DELETE",
      params,
    });
  },
  getAllApplicantsinfo(params) {
    return request({
      url: `${baseUrl}/all-applicant-information`,
      method: "GET",
      params: params,
    });
  },
  addAuthorizedIndInfo(data, params) {
    return request({
      url: `${baseUrl}/add-authorization-ind-person-information`,
      method: "POST",
      data,
      params: params,
    });
  },
  updateAuthorizedIndInfo(data, params) {
    return request({
      url: `${baseUrl}/update-authorization-ind-person-information`,
      method: "PUT",
      data,
      params: params,
    });
  },
  addAuthorizedBusInfo(data, params) {
    return request({
      url: `${baseUrl}/add-authorization-bus-person-information`,
      method: "POST",
      data,
      params: params,
    });
  },
  updateAuthorizedBusInfo(data, params) {
    return request({
      url: `${baseUrl}/update-authorization-bus-person-information`,
      method: "PUT",
      data,
      params: params,
    });
  },
};
