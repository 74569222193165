<template>
  <v-container>
    <v-row>
      <p class="font-weight-bold">{{ $t("bus_declaration") }}</p>
      <p class="d-flex">
        <v-checkbox
          class="mt-0"
          v-model="addProfilePayload.is_declared"
          @input="$v.is_declared.$touch()"
          :error-messages="applicantErrors"
          hide-details
        ></v-checkbox
        ><span class="mt-1">{{ $t("bus_declaration_text") }}</span>
      </p>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn
          color="primary"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickNext"
          >{{ $t("Submit") }}
        </v-btn>
      </v-col>
    </v-row>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
  </v-container>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import alerts from "@/mixins/alerts";
import { IndbusinessDocs } from "../../utils/BusDocuments";
import businessService from "../../services/BusinessuserService/businessuserservice";
export default {
  mixins: [alerts],
  data: () => ({
    loader: false,
    businessDocument: IndbusinessDocs(),
    isAllDocsUploaded: false,
    addProfilePayload: {
      is_declared: "",
    },
  }),
  computed: {
    applicantErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.is_declared.$dirty) return errors;
      !this.$v.addProfilePayload.is_declared.required &&
        errors.push("required");
      return errors;
    },
  },
  validations() {
    return {
      addProfilePayload: {
        is_declared: {
          required,
        },
      },
    };
  },
  mounted() {
    this.getdeclaration();
  },
  components: {
    Loading,
  },
  methods: {
    onClickNext() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.postList();
    },
    checkMissingRequiredDocs(docs, uploadedDocs) {
      return docs.some((doc) => doc.required && !uploadedDocs[doc.code]);
    },
    async getuploads() {
      this.loader = true;
      try {
        const response = await businessService.getUploadBusDocs();
        if (response && response.data.status_code == 200) {
          this.loader = false;
          const docsupload = response.data.data[0];
          return (this.isAllDocsUploaded = this.checkMissingRequiredDocs(
            this.businessDocument,
            docsupload
          ));
          // this.businessDocument.map((doc) => {
          //   for (let key in docsupload) {
          //     if (doc.required && doc.code == key) {
          //       this.isAllDocsUploaded = docsupload[`${key}`] ? true : false;
          //     }
          //   }
          //   return;
          // });
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
        console.warn("Error", error);
      }
    },
    async postList() {
      await this.getuploads();
      if (!this.isAllDocsUploaded) {
        this.addDeclaration();
      } else {
        this.showAlertError(
          "Error: Please Upload Required Documents in Document Section."
        );
      }
    },
    async getdeclaration() {
      try {
        const responseData = await businessService.getDeclarationData();
        if (responseData && responseData.data.status_code == 200) {
          this.addProfilePayload = {
            is_declared: responseData.data.data[0].is_declared,
          };
        }
      } catch (error) {
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
      }
    },

    async addDeclaration() {
      this.loader = true;
      try {
        const responseData = await businessService.addDeclarationData(
          this.addProfilePayload,
          {
            is_declared: this.addProfilePayload.is_declared,
          }
        );
        if (responseData && responseData.data.status_code == 200) {
          this.showAlertSuccess(responseData.data.message);
          this.loader = false;
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
        console.warn("Error", error);
      }
    },
  },
};
</script>
