<template>
  <v-card flat>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-card-title class="pa-2 ma-0">
      <span class="text-h6"> {{ $t("bus_share_holder") }}</span>
    </v-card-title>
    <v-divider class="p-0 m-0"></v-divider>
    <v-card-text>
      <v-container>
        <v-form v-model="valid" ref="busApplicant">
          <v-row>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("companyname_benificary")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.full_name"
                :rules="EntityRules.CompanyNameRules"
                dense
                :placeholder="$t('companyname_benificary')"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("registration_no_business")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('registration_no_placeholder')"
                dense
                outlined
                required
                v-model="editedItem.registration_number"
                :rules="EntityRules.RegistrationNoRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("date_of_incorporation_business")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('date_of_incorporation_placeholder')"
                dense
                type="date"
                outlined
                :max="new Date().toJSON().slice(0, 10)"
                required
                v-model="editedItem.dob"
                :rules="EntityRules.DoiRules"
                @change="ValidateDateofIncop(editedItem.dob)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("country") }}</label>
              <v-autocomplete
                :placeholder="$t('country')"
                :items="country_options"
                item-text="name"
                item-value="id"
                v-model="editedItem.country_code"
                outlined
                required
                dense
              ></v-autocomplete>
            </v-col>

            <!-- <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("address_beneficary") }}</label>
              <v-text-field
                :placeholder="$t('address_beneficary')"
                outlined
                required
                dense
                v-model="editedItem.address"
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("emailAddress")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.email_id"
                :placeholder="$t('email_placeholder')"
                :rules="EntityRules.emailValidations"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("phoneNumber") }}</label>
              <vue-phone-number-input
                default-country-code="AU"
                @update="onChangeNumber"
                v-model="editedItem.phone_number"
                class=""
                required
              />
              <!-- <span style="color: #ff5252; font-size: 12px">{{
                          contactNoErrors[0]
                        }}</span> -->
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("nature_of_business_business")
                }}<span style="color: #f00">*</span></label
              >
              <v-autocomplete
                v-model="editedItem.nature_of_business"
                :rules="EntityRules.natureOfBusinessErrors"
                :items="natureofbusList"
                item-text="name"
                item-value="id"
                :placeholder="$t('nature_of_business_placeholder')"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <label class="ma-0 pa-0"
                >{{ $t("share_holder_percentage")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('share_holder_percentage')"
                v-model="editedItem.percentage_of_shares"
                suffix="%"
                type="number"
                :rules="EntityRules.sharesRules"
                outlined
                required
                hide-spin-buttons
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="ma-0 pa-0"
                >{{ $t("no_of_shares")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('no_of_shares')"
                v-model="editedItem.no_of_shares"
                type="number"
                :rules="EntityRules.NoofsharesRules"
                outlined
                required
                hide-spin-buttons
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider class="p-o m-0"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        @click="close"
        v-if="!editedItem.biz_user_id"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        @click="onClickNext()"
        v-if="!editedItem.biz_user_id"
        >Save</v-btn
      >
      <v-btn
        color="primary"
        outlined
        @click="close"
        v-if="editedItem.biz_user_id"
      >
        Cancel
      </v-btn>
      <v-btn color="primary" @click="update()" v-if="editedItem.biz_user_id"
        >update</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { i18n as $ } from "../../../locale/index";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { findDatePrevious } from "../../../utils/helper";
// import { BusUserTypes } from "../../../utils/BusDocuments";
import validatemixin from "@/mixins/Busvalidate.mixin";
import axios from "axios";
export default {
  mixins: [validatemixin],
  data: () => ({
    id_type: "",
    dialog: false,
    valid: true,
    currentDate: new Date().toJSON().split("T")[0],
    // buscheckboxes: BusUserTypes(),
    Entitycheckbox: [],
    validationFields: [],
    natureofbusList: [],
    backPageUrl: null,
    frontPageUrl: null,
    dialogDelete: false,
    dobmenu: false,
    kycStatus: null,
    disableBystatus: null,
    id_type_back: null,
    issue_location: null,
    maxDate: "",
    minDate: "",
    isDobError: false,
    expriydate: false,
    isValidNumber: false,
    country_options: [],
    isSubmitted: false,
    isUploading: false,
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    countrySelected: {},

    getinformation: [],
    editedIndex: -1,
    biz_user_id: "",
    imageViewerUrl: "",
    isImageViewer: false,
    userId: null,
    editedItem: {
      full_name: "",
      country_code: "",
      registration_number: null,
      dob: null,
      address: "",
      email_id: "",
      phone_number: "",
      nature_of_business: "",
      percentage_of_shares: null,
      no_of_shares: null,
      type: "Shareholder",
      user_type: "BUS",
    },
    deleteId: "",
    doistate: false,
    EntityRules: {
      CompanyNameRules: [(v) => !!v || $.t("CompanyNameRules")],
      RegistrationNoRules: [(v) => !!v || $.t("RegistrationNoRules")],
      DoiRules: [(v) => !!v || $.t("DoiRules")],
      natureOfBusinessErrors: [(v) => !!v || $.t("natureOfBusinessErrors")],
      contactNoErrors: [(v) => !!v || $.t("contactNoErrors")],
      emailValidations: [
        (v) => !!v || $.t("email_errors"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          $.t("invalid_email"),
      ],
      corporateAddErrors: [
        (v) => !!v || $.t("address_req"),
        (v) => v.length < 98 || $.t("address_minlength"),
      ],
      countryErrors: [(v) => !!v || $.t("country_field_required")],
      sharesRules: [
        (v) => !!v || $.t("shares_percentage"),
        (v) => v <= 100 || $.t("no_ofsharesmsg"),
      ],
      NoofsharesRules: [(v) => !!v || $.t("no_ofshares")],
    },
  }),
  name: "BasicDetails",
  components: {
    VuePhoneNumberInput,
  },
  props: {
    selctedEntity: {
      type: String,
    },
    selectedBUSData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("Transactional", [
      "realtion_list",
      "nationality_list",
      "occupation_list",
    ]),
    ...mapGetters("Authentication", ["country_list"]),
  },

  watch: {
    countrySelected(newVal) {
      console.log("Selected country", newVal);
      if (Object.keys(newVal).length > 0) {
        this.editedItem.country_code = newVal.name;
        this.editedItem.applicant_country_code = newVal.iso3;
      }
    },
  },

  async mounted() {
    if (this.selectedBUSData && this.selectedBUSData.biz_user_id) {
      this.editedItem = { ...this.selectedBUSData };
      console.log("selectedBUSData", this.selectedBUSData);
      this.editedItem.country_code =
        this.selectedBUSData && this.selectedBUSData.country.iso_alpha3;
      this.editedItem.nature_of_business =
        this.selectedBUSData &&
        this.selectedBUSData.nature_of_business.value_code;
    }
    await this.getCountryList();
    await this.getnatureOfBusiness();
    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },

  methods: {
    close() {
      this.$emit("closeBusDialog");
    },
    ValidateDateofIncop(value) {
      console.log("value", value);
      let presentDay = new Date().toJSON().slice(0, 10);
      console.log("presentDay", presentDay);
      if (value > presentDay) {
        this.doistate = true;
        this.EntityRules.DoiRules.push($.t("invalid_doi"));
      } else {
        this.doistate = false;
        this.EntityRules.DoiRules = [(v) => !!v || "DOI is required"];
      }
    },

    async getnatureOfBusiness() {
      const responseData = await this.$store.dispatch(
        "Transactional/getnatureOfBusiness"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.natureofbusList.push({
          name: i.value,
          id: i.value_code,
        });

        // this.sourceList_objs.push(i);
      });
      console.log("this.source_options", this.source_options);
    },

    ValidateAge(value) {
      if (!value) this.isDobError = false;
      else this.isDobError = !(value <= this.maxDate && value >= this.minDate);
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },

    async getCountryList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        // this.onChangeCounty('MYS');
      });
      console.log("country_options", this.country_options);
    },
    editItem(item) {
      this.editedIndex = this.getinformation.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.applicant_type = item.type;
      console.log("image data", item.type);
      this.idtype_objs.map((ele) => {
        console.log("ele", ele);
        if (ele.value == item.id_type_code) {
          this.editedItem.id_type_code = ele.value_code;
        }
      });
      this.dialog = true;
    },

    onClickNext() {
      if (this.$refs.busApplicant.validate()) {
        this.save();
      }
    },

    onClickBack() {
      this.$emit("onClickBack");
    },

    async save() {
      this.loader = true;
      if (this.editedIndex > -1) {
        Object.assign(this.getinformation[this.editedIndex], this.editedItem);
      }
      try {
        const responseData = await axios.post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-authorization-bus-person-information`,
          this.editedItem,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );

        console.log("responseData", responseData);

        if (
          responseData &&
          responseData.data &&
          responseData.data.success === true
        ) {
          this.userId = responseData.data.data[0].biz_user_id;
          console.log(" this.userId", this.userId);
          console.log("ll", responseData);
          this.alertMsg.status = true;
          this.alertMsg.text = responseData.data.message;
          this.alertMsg.type = "success";
          this.alertMsg.color = "green";
          this.$emit("getapplicant");
        } else {
          this.alertMsg.status = true;
          this.alertMsg.text = `Creation Failed: ${responseData.data.message}`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "warning";
        }
      } catch (error) {
        this.alertMsg.status = true;
        this.alertMsg.text = `Creation Failed: please try again sometime`;
        this.alertMsg.type = "error";
        this.alertMsg.color = "error";
      }
      this.close();
    },

    update() {
      if (this.$refs.busApplicant.validate()) {
        this.updateData();
      }
    },

    updateData() {
      this.loader = true;

      axios
        .put(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/update-authorization-bus-person-information?biz_user_id=${this.editedItem.biz_user_id}`,
          this.editedItem,

          {
            headers: {
              ContentType: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        )
        .then((responseData) => {
          console.log("responseData", responseData);
          if (responseData && responseData.data.status_code == 200) {
            this.alertMsg.status = true;
            this.alertMsg.text = responseData.data.message;
            this.alertMsg.type = "success";
            this.alertMsg.color = "green";
            this.$emit("getapplicant");
            this.close();
          } else {
            this.alertMsg.status = true;
            this.alertMsg.text = `Update Failed: ${responseData.data.message}`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "warning";
          }
          console.log("respose data", responseData);
        })
        .catch((error) => {
          console.log(error.response);
          this.alertMsg.status = true;
          this.alertMsg.text = `Update Failed: please try again sometime`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "error";
        });
      setTimeout(() => {
        this.loader = false;
      }, 300);
    },
  },
};
</script>
<style>
.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
