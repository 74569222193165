<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <v-alert
      v-if="isAllDocsUploaded"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      text
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("want_to_delete_info") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small color="primary" @click="closeDelete">
            {{ $t("cancel_label") }}</v-btn
          >
          <v-btn
            class="elevation-0"
            color="primary"
            small
            @click="DeleteDocument()"
          >
            {{ $t("okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-for="doc in EntityDocs" :key="doc.doc_name.name">
      <v-col cols="12" md="8"
        >{{ doc.doc_name.name }}
        <span v-if="doc.doc_name.required" style="color: #f00">*</span></v-col
      >
      <v-btn
        outlined
        title="Download"
        color="success"
        fab
        small
        class="ma-2 elevation-0"
        v-if="doc.doc_val"
        ><v-icon @click="openDoc(doc.doc_val)">
          mdi-file-download</v-icon
        ></v-btn
      >
      <v-btn
        outlined
        title="Delete"
        color="red"
        fab
        :disabled="iskycApproved"
        small
        class="ma-2 elevation-0"
        v-if="doc.doc_val"
        ><v-icon @click="openDeleteDoc(doc)"> mdi-delete</v-icon></v-btn
      >

      <v-menu
        v-model="doc.isactive"
        :close-on-content-click="false"
        fixed
        :nudge-width="400"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ma-2 elevation-0"
            outlined
            title="Update"
            :disabled="iskycApproved"
            dark
            v-bind="attrs"
            v-on="on"
            fab
            small
          >
            <v-icon dark small>
              {{ doc.doc_val ? "mdi-pencil" : "mdi-cloud-upload" }}</v-icon
            >
          </v-btn>
        </template>

        <v-card>
          <v-form ref="uploadDoc">
            <v-col cols="12"
              >{{ doc.doc_name && doc.doc_name.name
              }}<span style="color: #f00">*</span>
            </v-col>
            <v-chip
              label
              class="ms-3"
              color="primary"
              v-if="doc && doc.doc_val && doc.doc_val"
            >
              <v-icon small class="pe-2">mdi-file</v-icon>
              <p class="mb-0">
                {{ doc && doc.doc_val && doc.doc_val.split("/").pop() }}
              </p>
            </v-chip>
            <v-col cols="12">
              <v-file-input
                v-model="document_val"
                dense
                accept=".pdf"
                :placeholder="$t('upload_file')"
                :error-messages="docError"
                outlined
                ref="idBusdoc"
                required
                prepend-icon=""
                @change="fileUpload($event)"
              >
                <!-- <template v-slot:append>
                  <v-icon @click="openIdfirst" aria-hidden="false" class="color-primary">
                    mdi-cloud-upload
                  </v-icon>
                </template> -->
              </v-file-input>
            </v-col>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text
                @click="(doc.isactive = false), (document_val = null)"
              >
                {{ $t("cancel_label") }}</v-btn
              >
              <v-btn color="primary" text @click="uploadDocument(doc)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>
import { webbreakdown } from "../../utils/breakpoints";
import Loading from "vue-loading-overlay";
import { IndbusinessDocs } from "../../utils/BusDocuments";
import businessService from "../../services/BusinessuserService/businessuserservice.js";
import alerts from "@/mixins/alerts";
export default {
  mixins: [alerts],
  data: () => ({
    webbreakdown,
    businessDocument: IndbusinessDocs(),
    // SetBusinessdocs: SetbusinessDocs(),
    dialogDelete: false,
    selectedDeteledDoc: null,
    isAllDocsUploaded: false,
    showPopup: false,
    EntityDocs: null,
    document_val: null,
    loader: false,
    dialog: false,
    isshowdoc: false,
    SelectedSrc: null,
    EntityName: "",
    document_name: null,
    docError: "",
    docUploadType: "alldocs",
    alertMsg: {
      status: true,
      text: "To proceed,please upload the following required documents.",
      timeout: 2000,
      color: "primary",
      type: "info",
    },
    selectedDoc: {},
    docsupload: {},
    recievedDocs: [],
    menu: false,
    error: false,
    docrules: [(v) => !!v || "Document is required"],
  }),
  components: {
    Loading,
  },
  name: "BankDetails",
  props: {
    selctedEntity: {
      type: String,
    },
    iskycApproved: {
      type: Boolean,
    },
  },
  mounted() {
    this.getuploads();
    this.returnBusinessDocs(this.businessDocument);
  },
  computed: {},
  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },
    openDeleteDoc(doc) {
      this.selectedDeteledDoc = doc;
      this.dialogDelete = true;
    },
    openIdfirst() {
      this.$refs.idBusdoc.$el.querySelector("input").click();
    },
    openDoc(docurl) {
      console.log(docurl);
      if (docurl) {
        this.loader = true;
        this.SelectedSrc = docurl;
        const url = docurl;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "excel.pdf");
        document.body.appendChild(link);
        link.click();
        this.loader = false;
        // this.isshowdoc = true;
      } else {
        return "";
      }
    },
    checkMissingRequiredDocs(docs, uploadedDocs) {
      return docs.some((doc) => doc.required && !uploadedDocs[doc.code]);
    },
    async getuploads() {
      this.loader = true;
      try {
        const response = await businessService.getUploadBusDocs();
        if (response && response.data.status_code == 200) {
          this.loader = false;
          this.docsupload = response.data.data[0];
          this.recievedDocs = this.EntityDocs.map((doc) => {
            for (let key in this.docsupload) {
              // if (doc.doc_name.required && doc.doc_name.code === key) {
              //   this.isAllDocsUploaded = this.docsupload[`${key}`]
              //     ? false
              //     : true;
              // }
              if (doc.doc_name.code === key) {
                console.log("ele", this.docsupload[`${key}`]);
                return {
                  doc_name: doc.doc_name,
                  doc_val: response.data.data[0][`${key}`],
                  doc_url: response.data.data[0][`${key}`],
                  isactive: false,
                };
              }
            }
          });
          this.isAllDocsUploaded = this.checkMissingRequiredDocs(
            this.businessDocument,
            this.docsupload
          );
          this.$emit("checkDocsUploaded", this.isAllDocsUploaded);
          this.EntityDocs = this.recievedDocs;
          console.log("this.EntityDocs", this.EntityDocs);
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
        console.warn("Error", error);
      }
    },
    async DeleteDocument() {
      this.loader = true;
      try {
        const response = await businessService.DeleteBusdocs({
          doc_type: this.selectedDeteledDoc.doc_name.value_code,
        });
        if (response && response.data.status_code == 200) {
          this.showAlertSuccess(response.data.message);
          this.loader = false;
          this.dialogDelete = false;
          this.getuploads();
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
        console.warn("Error", error);
      }
    },
    async uploadDocument(doc) {
      console.log("doc.doc_val", doc);
      if (this.document_val && !this.error) {
        this.docError = "";
        this.loader = true;
        let fileData = new FormData();
        fileData.append("document", this.document_val);
        try {
          const responseData = await businessService.UploadBusDocs(fileData, {
            type: 2,
            doc_type: doc.doc_name.value_code,
          });
          if (responseData && responseData.data.status_code == 200) {
            this.showAlertSuccess(responseData.data.message);
            this.loader = false;
            doc.isactive = false;
            this.document_val = null;
            this.getuploads();
          } else {
            this.docError = "";
            this.loader = false;
            this.showAlertError(responseData.data.message);
          }
        } catch (error) {
          this.document_val = null;
          this.docError = "";
          this.loader = false;
          this.showAlertError(
            "Error: Something went wrong please try again later."
          );
          console.warn("Error", error);
        }
      } else {
        this.docError = "Document is required";
      }
    },

    fileUpload(event) {
      console.log(event.name);
      if (event && event.name) {
        this.docError = "";
        this.error = false;
      } else {
        this.docError = "Document is required";
        this.error = true;
      }
    },
    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    returnBusinessDocs(documents) {
      this.EntityDocs = documents.map((docval) => {
        return {
          doc_name: docval,
          doc_val: null,
          doc_url: null,
          isactive: false,
        };
      });
      console.log("thisthose", this.EntityDocs);

      this.getuploads();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
