<template>
  <v-container>
    <v-card class="elevation-0">
      <v-tabs
        v-model="tab"
        grow
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        elevation="0"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab> Entity Information </v-tab>
        <v-tab> Authorize Person Details </v-tab>
        <v-tab> Management information </v-tab>
        <v-tab> Documents </v-tab>
        <v-tab :disabled="isAllDocsUploaded"> Declaration </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card elevation-0 :disabled="iskycApproved">
            <v-card-text
              ><bus-entityinfo @selctedEntitytype="EntityType"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card :disabled="iskycApproved">
            <v-card-text
              ><bus-applicantinfo :selctedEntity="selctedEntity"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text
              ><bus-authorization
                :iskycApproved="iskycApproved"
                :selctedEntity="selctedEntity"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text
              ><bus-documents
                :iskycApproved="iskycApproved"
                :selctedEntity="selctedEntity"
                @checkDocsUploaded="checkDocsUploaded"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <!-- <v-tab-item>
          <v-card flat>
            <v-card-text><bus-others /></v-card-text>
          </v-card>
        </v-tab-item> -->
        <v-tab-item>
          <v-card flat :disabled="iskycApproved">
            <v-card-text><bus-declaration /></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import BusEntityinfo from "./BusEntityinfo.vue";
import BusApplicantinfo from "./BusApplicantinfo.vue";
import BusDocuments from "./BusDocuments.vue";
// import BusOthers from "./BusOthers.vue";
import { IndbusinessDocs } from "../../utils/BusDocuments";
import BusDeclaration from "./BusDeclaration.vue";
import businessService from "../../services/BusinessuserService/businessuserservice.js";
// import { BusUserTypes } from "../../utils/BusDocuments";
import BusAuthorization from "../BusinessUserflow/BusAuthorization/BusAuthorization.vue";
export default {
  data() {
    return {
      tab: null,
      iskycApproved: false,
      isAllDocsUploaded: true,
      businessDocument: IndbusinessDocs(),
      items: [
        "Entity Information",
        "Authorize Person Details",
        "Management information",
        "Documents",
        "Declaration",
      ],
      selctedEntity: "",
      tabList: [],
      is_showAuthPage: true,
      // buscheckboxes: BusUserTypes(),
      hideForm: "",
    };
  },
  components: {
    BusEntityinfo,
    BusApplicantinfo,
    BusDocuments,
    // BusOthers,
    BusDeclaration,
    BusAuthorization,
  },
  created() {
    this.getProfile();
    this.getuploads();
  },
  methods: {
    checkDocsUploaded(val) {
      this.isAllDocsUploaded = val;
    },
    getProfile() {
      this.$store
        .dispatch("Authentication/getProfile")
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            if (
              res.data.data &&
              res.data.data[0] &&
              res.data.data[0].kyc_status
            ) {
              this.iskycApproved =
                res.data.data[0].kyc_status == "Approved" ? true : false;
              console.log("res.data.data)", res.data.data[0].kyc_status);
            }
          }
        })
        .catch((err) => console.error(err));
    },
    checkMissingRequiredDocs(docs, uploadedDocs) {
      return docs.some((doc) => doc.required && !uploadedDocs[doc.code]);
    },
    async getuploads() {
      this.loader = true;
      try {
        const response = await businessService.getUploadBusDocs();
        if (response && response.data.status_code == 200) {
          this.loader = false;
          const docsupload = response.data.data[0];
          this.isAllDocsUploaded = this.checkMissingRequiredDocs(
            this.businessDocument,
            docsupload
          );
          // this.businessDocument.map((doc) => {
          //   for (let key in docsupload) {
          //     if (doc.required && doc.code == key) {
          //       this.isAllDocsUploaded = docsupload[`${key}`] ? false : true;
          //     }
          //   }
          // });
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong please try again later."
        );
        console.warn("Error", error);
      }
    },
    EntityType(value) {
      this.selctedEntity = value;
      //to get boolean value of Authorization page required or not
      // for (let doc in this.buscheckboxes) {
      //   console.log("doc integer", doc);
      //   if (doc === value) {
      //     console.log("doc mauin", this.buscheckboxes[doc]);
      //     this.is_showAuthPage = this.buscheckboxes[doc].is_authrequired;
      //   }
      // }
      //to remove tab froms tab list
      // if (!this.is_showAuthPage) {
      //   this.tabList = this.items.filter(
      //     (val) => val !== "Authorization Information"
      //   );
      // } else {
      //   this.tabList = this.items;
      // }
    },
    // isHideform(value) {
    //   this.hideForm = value;
    // },
  },
};
</script>
