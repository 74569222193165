export function mobilebreakdown() {
  switch (this.$vuetify.breakpoint.name) {
    case "xs":
      return false;
    case "sm":
      return false;
    case "md":
      return true;
    case "lg":
      return true;
    case "xl":
      return true;
  }
}
export function webbreakdown() {
  switch (this.$vuetify.breakpoint.name) {
    case "xs":
      return true;
    case "sm":
      return true;
    case "md":
      return true;
    case "lg":
      return false;
    case "xl":
      return false;
  }
}
