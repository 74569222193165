<template>
  <v-card flat>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-card-text>
      <v-form v-model="valid" ref="busApplicant">
        <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("fullName") }}<span style="color: #f00">*</span></label
            >
            <v-text-field
              v-model="editedItem.full_name"
              :rules="validations.firstnameValidations"
              dense
              :placeholder="$t('fullName')"
              outlined
              required
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0">{{ $t("Gender") }}</label>
            <v-autocomplete
              :placeholder="$t('gender_placeholder')"
              :items="genderOptions"
              v-model="editedItem.gender"
              dense
              outlined
            ></v-autocomplete>
          </v-col> -->

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0">{{ $t("date_of_birth_business") }}</label>

            <v-menu
              ref="dobmenu"
              v-model="dobmenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              :offset-x="true"
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="editedItem.dob"
                  persistent-hint
                  clearable
                  v-on="on"
                  type="date"
                  dense
                  @change="ValidateAge"
                  :min="minDate"
                  :max="maxDate"
                  outlined
                >
                </v-text-field>
              </template>
            </v-menu>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0">{{
              $t("country_of_birth_business")
            }}</label>
            <v-autocomplete
              v-model="editedItem.country_of_birth"
              :placeholder="$t('country_of_birth_placeholder')"
              :items="country_options"
              item-text="name"
              item-value="id"
              outlined
              required
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("Nationality") }}<span style="color: #f00">*</span></label
            >
            <v-autocomplete
              :placeholder="$t('nationality_placeholder')"
              dense
              v-model="editedItem.nationality_code"
              :items="nationality_options"
              :rules="validations.nationalityValidations"
              item-text="name"
              item-value="id"
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("Occupation") }}<span style="color: #f00">*</span></label
            >
            <v-autocomplete
              v-model="editedItem.occupation_code"
              :items="occupation_options"
              item-text="name"
              item-value="id"
              :rules="validations.OcupationValidations"
              :placeholder="$t('occupation_placeholder')"
              dense
              outlined
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0">{{ $t("address_beneficary") }}</label>
            <v-text-field
              :placeholder="$t('address_beneficary')"
              outlined
              required
              dense
              v-model="editedItem.address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label
              >{{ $t("country_business")
              }}<span style="color: #f00">*</span></label
            >
            <v-autocomplete
              :placeholder="$t('select_country_placeholder')"
              :items="country_options"
              v-model="editedItem.country_code"
              item-text="name"
              item-value="id"
              :rules="validations.countryErrors"
              @input="onChangeCounty($event)"
              outlined
              required
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <label
              >{{ $t("state_business")
              }}<span style="color: #f00">*</span></label
            >
            <v-autocomplete
              v-model="editedItem.state_code"
              :items="state_options"
              :placeholder="$t('select_state_placeholder')"
              item-text="name"
              item-value="id"
              outlined
              required
              :rules="validations.mailingStateErrors"
              @input="onChangeState($event)"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <label>{{ $t("city") }}<span style="color: #f00">*</span></label>
            <v-autocomplete
              v-model="editedItem.city_code"
              :placeholder="$t('select_city_placeholder')"
              :items="city_options"
              item-text="txt"
              item-value="val"
              :rules="validations.mailingCityErrors"
              outlined
              required
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <label
              >{{ $t("postalCode") }}<span style="color: #f00">*</span></label
            >
            <v-combobox
              v-model="editedItem.zip_code"
              :items="postalCodeslist"
              :rules="validations.postalCodeErrors"
              :placeholder="$t('postal_code_placeholder')"
              outlined
              dense
            ></v-combobox>
          </v-col>
          <!-- <v-col cols="12" md="6" sm="12" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("emailAddress")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.email_id"
                :rules="validations.emailValidations"
                :placeholder="$t('email_placeholder')"
                dense
                outlined
                required
              ></v-text-field>
            </v-col> -->
          <!-- <v-col cols="12" md="6" sm="12" class="pt-0">
              <label class="ma-0 pa-0">{{
                $t("phoneNumber")
              }}</label>
              <vue-phone-number-input
                default-country-code="AU"
                @update="onChangeNumber"
                :error="!isValidNumber"
                v-model="editedItem.phone_number"
                class=""
                required
              />
             
            </v-col> -->
          <v-col
            cols="12"
            md="6"
            v-if="validationFields.includes('No of Shares')"
          >
            <label class="ma-0 pa-0"
              >{{ $t("share_holder_percentage")
              }}<span style="color: #f00">*</span></label
            >
            <v-text-field
              :placeholder="$t('share_holder_percentage')"
              v-model="editedItem.no_of_shares"
              suffix="%"
              type="number"
              :rules="validations.sharesRules"
              outlined
              required
              hide-spin-buttons
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("idType") }}<span style="color: #f00">*</span></label
            >
            <v-select
              v-model="editedItem.id_type_code"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="validations.id_proofrules"
              :items="idtypes_val"
              item-text="name"
              item-value="id"
              outlined
              required
              :placeholder="$t('idtype_select_placeholder')"
              dense
            ></v-select>

            <!-- <v-select
                  v-else
                  v-model="id_type_back"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="validations.id_proofrules"
                  :items="idtypes_val"
                  item-text="name"
                  item-value="id"
                  :disabled="disableValues || disableBystatus"
                  outlined
                  required
                  :placeholder="$t('idtype_select_placeholder')"
                  dense
                ></v-select> -->
          </v-col>

          <v-col
            cols="12"
            md="6"
            v-if="
              editedItem.id_type_code != 'MYKAD' &&
              editedItem.id_type_code != 'MyPR'
            "
          >
            <label class="ma-0 pa-0"
              >{{ $t("isuueDate") }}<span style="color: #f00">*</span></label
            >
            <v-text-field
              v-model="editedItem.id_issue_date"
              :rules="validations.issuedaterules"
              dense
              required
              outlined
              type="date"
              :max="currentDate"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("Location")
              }}<span
                v-if="editedItem.id_type_code === 'Passport'"
                style="color: #f00"
                >*</span
              ></label
            >
            <v-autocomplete
              v-model="editedItem.id_issue_country"
              item-text="name"
              item-value="id"
              :items="country_options"
              :rules="validations.issue_locationRules"
              :placeholder="$t('Location')"
              dense
              outlined
              required
              :min="currentDate"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            v-if="
              editedItem.id_type_code != 'MYKAD' &&
              editedItem.id_type_code != 'MyPR'
            "
          >
            <label class="ma-0 pa-0"
              >{{ $t("expiryDate") }}<span style="color: #f00">*</span></label
            >
            <v-text-field
              v-model="editedItem.id_expiry_date"
              :rules="validations.expDateRules"
              dense
              outlined
              required
              type="date"
              :min="currentDate"
            />
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("idNumber") }}<span style="color: #f00">*</span></label
            >
            <v-text-field
              v-model="editedItem.id_number"
              :rules="validations.id_numberRules"
              :placeholder="$t('id_number_placeholder')"
              outlined
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("AuthAddressProof")
              }}<span style="color: #f00">*</span></label
            >
            <v-file-input
              v-model="editedItem.address_proof"
              :rules="validations.firstpage_rules"
              dense
              accept="image/png, image/jpeg, image/bmp"
              :placeholder="$t('idtype_first_page_placeholder')"
              outlined
              ref="addressProof"
              required
              prepend-icon=""
              @change="
                genrateURL(
                  'addressProofUrl',
                  editedItem.address_proof,
                  'address_proof'
                )
              "
            >
              <template v-slot:append>
                <v-icon
                  aria-hidden="false"
                  class="color-primary"
                  @click="openAddressProof"
                >
                  mdi-cloud-upload
                </v-icon>
              </template>

              <template v-slot:append-outer>
                <v-img
                  v-if="addressProofUrl"
                  class="ml-1"
                  max-height="40"
                  max-width="50"
                  :src="addressProofUrl"
                  @click="handleImageViewer(addressProofUrl)"
                />
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0"
              >{{ $t("IDFirstPage") }}<span style="color: #f00">*</span></label
            >
            <v-file-input
              v-model="editedItem.ID_First_Page"
              :rules="validations.firstpage_rules"
              dense
              accept="image/png, image/jpeg, image/bmp"
              :placeholder="$t('idtype_first_page_placeholder')"
              outlined
              ref="idfirstfile"
              required
              prepend-icon=""
              @change="
                genrateURL(
                  'frontPageUrl',
                  editedItem.ID_First_Page,
                  'ID_First_Page'
                )
              "
            >
              <template v-slot:append>
                <v-icon
                  aria-hidden="false"
                  class="color-primary"
                  @click="openIdfirst"
                >
                  mdi-cloud-upload
                </v-icon>
              </template>

              <template v-slot:append-outer>
                <v-img
                  v-if="frontPageUrl"
                  class="ml-1"
                  max-height="40"
                  max-width="50"
                  :src="frontPageUrl"
                  @click="handleImageViewer(frontPageUrl)"
                />
              </template>
            </v-file-input>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0">
            <label class="ma-0 pa-0">{{ $t("IDLastPage") }}</label>
            <v-file-input
              v-model="editedItem.ID_Last_Page"
              dense
              accept="image/png, image/jpeg, image/bmp"
              :placeholder="$t('idtype_last_page_placeholder')"
              outlined
              ref="idlastfile"
              required
              prepend-icon=""
              @change="
                genrateURL(
                  'backPageUrl',
                  editedItem.ID_Last_Page,
                  'ID_Last_Page'
                )
              "
            >
              <template v-slot:append>
                <v-icon
                  aria-hidden="false"
                  class="color-primary"
                  @click="openIdlast"
                >
                  mdi-cloud-upload
                </v-icon>
              </template>

              <template v-slot:append-outer>
                <v-img
                  v-if="backPageUrl"
                  class="ml-1"
                  max-height="40"
                  max-width="50"
                  :src="backPageUrl"
                  @click="handleImageViewer(backPageUrl)"
                />
              </template>
            </v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        @click="editedItem.biz_user_id ? update() : onClickNext()"
        >{{ editedItem.biz_user_id ? "Update" : "Save" }}</v-btn
      >
    </v-card-actions>
    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '435',
        PicWidth: 600,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { i18n as $ } from "../../locale/index";
// import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { findDatePrevious } from "../../utils/helper";
// import { BusUserTypes } from "../../utils/BusDocuments";
import validatemixin from "@/mixins/Busvalidate.mixin";
import axios from "axios";
import imageViewer from "../tools/imageViewer.vue";
import handleImageUpload from "../../mixins/compressImage.mixin";
// import { issueDate, expireDate } from "../../../utils/validate";

export default {
  mixins: [validatemixin, handleImageUpload],
  data: () => ({
    id_type: "",
    dialog: false,
    valid: true,
    currentDate: new Date().toJSON().split("T")[0],
    // buscheckboxes: BusUserTypes(),
    Entitycheckbox: [],
    validationFields: [],
    backPageUrl: null,
    frontPageUrl: null,
    addressProofUrl: null,
    dialogDelete: false,
    dobmenu: false,
    kycStatus: null,
    disableBystatus: null,
    id_type_back: null,
    issue_location: null,
    maxDate: "",
    minDate: "",
    isDobError: false,
    expriydate: false,
    isValidNumber: false,
    country_options: [],
    relation_options: [],
    idtypes: [],
    idtypes_val: [],
    idtype_objs: [],
    nationality_options: [],
    occupation_options: [],
    occupation_objs: [],
    postalCodeslist: [],
    state_options: [],
    city_options: [],
    genderOptions: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
    ],
    isSubmitted: false,
    isUploading: false,
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    countrySelected: {},
    headers: [
      {
        text: "Full Name",
        align: "start",
        sortable: false,
        value: "full_name",
      },
      { text: "Type", value: "type" },
      { text: "Email", value: "email_id" },
      { text: "ID type", value: "id_type_code" },
      { text: "ID number", value: "id_number" },
      { text: "First page", value: "first_page" },
      { text: "Last page", value: "last_page" },
      { text: "Actions", value: "actions" },
    ],
    getinformation: [],
    editedIndex: -1,
    biz_user_id: "",
    imageViewerUrl: "",
    isImageViewer: false,
    userId: null,
    editedItem: {
      applicant_type: "Representative",
      full_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: null,
      email_id: "",
      phone_number: "",
      nationality_code: "",
      occupation_code: "",
      country_of_birth: "",
      country_code: "AUS",
      // applicant_country_code: "",
      id_type_code: null,
      id_number: null,
      ID_First_Page: null,
      address_proof: null,
      ID_Last_Page: null,
      no_of_shares: null,
      id_issue_date: null,
      id_expiry_date: null,
      id_issue_country: "AUS",
      biz_user_id: null,
      address: null,
      state_code: null,
      city_code: null,
      zip_code: null,
    },
    deleteId: "",

    validations: {
      emailValidations: [
        (v) => !!v || $.t("emailMsg1"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          $.t("emailMsg2"),
      ],
      firstnameValidations: [
        (v) => !!v || $.t("fullname_required"),
        (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t("invalid_fullname"),
      ],
      nationalityValidations: [(v) => !!v || $.t("nationalityMsg1")],
      OcupationValidations: [(v) => !!v || "Occupation is required"],
      selfy_rules: [(v) => !!v || $.t("file_select_placeholder")],
      firstpage_rules: [(v) => !!v || $.t("file_select_placeholder")],
      issuedaterules: [(v) => !!v || $.t("issueDateMsg1")],
      issue_locationRules: [],
      expDateRules: [(v) => !!v || $.t("expiryDateMsg1")],
      id_proofrules: [(v) => !!v || $.t("idtypeMsg1")],
      id_numberRules: [(v) => !!v || $.t("idnumberMsg1")],
      interviewData: [(v) => !!v || $.t("interviewDate")],
      interviewTime: [(v) => !!v || $.t("interviewTime")],
      mailingStateErrors: [(v) => !!v || $.t("state_field_is_required")],
      mailingCityErrors: [(v) => !!v || $.t("city_placeholder_beneficary")],
      postalCodeErrors: [
        (v) => !!v || $.t("postalcode_placeholder_beneficary"),
      ],
      countryErrors: [(v) => !!v || $.t("country_field_required")],
    },
  }),
  name: "BasicDetails",
  components: {
    // VuePhoneNumberInput,
    imageViewer,
  },
  props: {
    selctedEntity: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("Transactional", [
      "realtion_list",
      "nationality_list",
      "occupation_list",
    ]),
    ...mapGetters("Authentication", [
      "country_list",
      "state_list",
      "city_list",
    ]),
  },

  watch: {
    "editedItem.applicant_type"(applicant_type) {
      console.log("applicant_type", applicant_type);
      this.validationFields = [...this.getRequired(applicant_type)];
      console.log("applicant_type:", this.getRequired(applicant_type));
    },

    "editedItem.id_type"(val) {
      this.idtype_objs.map((i) => {
        if (val === i.parameter_value) {
          this.id_type_code = i.parameter_code;
        }
      });
    },

    id_type(val) {
      this.idtype_objs.map((i) => {
        if (val === i.value) {
          this.id_type_code = i.id;
        }
      });
      if (val == "MYKAD" || val == "MyPR") {
        this.editedItem.id_issue_country = "AUS";
        this.disableIssueLoc = true;
      } else {
        // this.user_details.issue_location = "";
        this.disableIssueLoc = false;
      }
      if (val === "Passport") {
        this.validations.issue_locationRules = [
          (v) => !!v || $.t("issue_location_placeholder"),
        ];
      } else {
        this.validations.issue_locationRules = [];
      }
    },

    "editedItem.issue_location"(val) {
      this.country_objs.map((i) => {
        if (val === i.name) {
          this.issue_location_code = i.iso3;
        }
      });
    },

    "editedItem.id_issue_date"(val) {
      if (!val < new Date().toJSON().split("T")[0]) {
        this.validations.issuedaterules.push($.t("issue_date_valid"));
      } else {
        this.validations.issuedaterules = [(v) => !!v || $.t("issueDateMsg1")];
      }
    },
    "editedItem.id_expiry_date"(val) {
      if (!val > new Date().toJSON().split("T")[0]) {
        this.validations.expDateRules.push(
          (v) => !!v || $.t("expiry_date_issue_validate")
        );
      } else {
        this.validations.expDateRules = [(v) => !!v || $.t("expiryDateMsg1")];
      }
    },

    countrySelected(newVal) {
      console.log("Selected country", newVal);
      if (Object.keys(newVal).length > 0) {
        this.editedItem.country_code = newVal.name;
        this.editedItem.applicant_country_code = newVal.iso3;
      }
    },
  },

  created() {
    // this.initialize();
  },

  mounted() {
    this.getRelationship();
    this.getCountryList();
    this.getOccupation();
    this.getNationalityList();
    this.getPostalCodes();
    this.getidtypes();
    this.getapplicant();
    this.onChangeCounty("AUS");
    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },

  methods: {
    async getPostalCodes() {
      try {
        const responseData = await this.$store.dispatch(
          "Transactional/getPostalCodes"
        );
        if (responseData.data.status_code === 200) {
          this.postalCodeslist = [...responseData.data.data];
        }
        console.log("postalCodes", responseData);
      } catch (err) {
        console.log(err);
      }
    },
    genrateURL(keyField, value, assignKey) {
      if (!keyField) return;

      if (!value) {
        if (assignKey == "ID_First_Page") {
          this.frontPageUrl = null;
        } else if (assignKey === "ID_Last_Page") {
          this.backPageUrl = null;
        }
        this.editedItem[`${assignKey}`] = null;
      } else {
        if (assignKey == "ID_First_Page") {
          this.frontPageUrl = URL.createObjectURL(value);
        } else if (assignKey === "ID_Last_Page") {
          this.backPageUrl = URL.createObjectURL(value);
        } else if (assignKey === "address_proof") {
          this.addressProofUrl = URL.createObjectURL(value);
        }
        this.editedItem[`${assignKey}`] = value;
      }
    },
    async onChangeCounty(iso3) {
      await this.$store.dispatch("Authentication/getStateByIso3", iso3);
      if (Array.isArray(this.state_list.response.data)) {
        this.state_options = this.state_list.response.data.map((i) => {
          return {
            name: i.name,
            id: i.state_code,
          };
        });
        console.log("stae", this.state_options);
      }
      if (this.editedItem.biz_user_id && this.editedItem.state_code) {
        this.onChangeState(this.editedItem.state_code);
      }
    },
    async onChangeState(state) {
      console.log("state", state);
      let id = {
        state: state,
        country: this.editedItem.country_code,
      };
      await this.$store.dispatch("Authentication/getCityByIso3", id);
      if (Array.isArray(this.city_list.response.data)) {
        this.city_options = this.city_list.response.data.map((i) => {
          return {
            val: i.id,
            txt: i.name,
          };
        });
      }
      // if (this.is_update && this.combinedData.mailing_city.id) {
      //   console.log("city", this.city_options);
      //   this.addressInfo.mailing_city = parseInt(
      //     this.combinedData.mailing_city.id
      //   );
      // }
    },

    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },

    getapplicant() {
      this.isLoading = true;
      axios({
        url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/get-applicant-information-data`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          this.isLoading = false;

          if (response.status === 200) {
            // const getinform = response.data.data[0];
          }
          console.log("getdata", response.data.data[0]);
          const applicant_data = response.data.data[0];
          this.editedItem = {
            applicant_type: "Representative",
            full_name: applicant_data.full_name,
            middle_name: "",
            last_name: "",
            gender: applicant_data.gender,
            dob: applicant_data.dob,
            address: applicant_data.address,
            email_id: applicant_data.email_id,
            phone_number: applicant_data.phone_number,
            nationality_code:
              applicant_data.nationality &&
              applicant_data.nationality.iso_alpha3,
            occupation_code:
              applicant_data.occupation && applicant_data.occupation.value_code,
            zip_code: applicant_data.zip_code,
            country_code:
              applicant_data.country && applicant_data.country.iso_alpha3,
            country_of_birth:
              applicant_data.country_of_birth &&
              applicant_data.country_of_birth.iso_alpha3,
            state_code:
              applicant_data.country && applicant_data.state.state_code,
            city_code: applicant_data.country && applicant_data.city.id,
            // applicant_country_code: "",
            id_type_code:
              applicant_data.id_type && applicant_data.id_type.value_code,
            id_number: applicant_data.id_number,
            ID_First_Page: applicant_data.id_first_page
              ? {
                  name: applicant_data.id_first_page?.split("/").pop(),
                  value: applicant_data.id_first_page,
                }
              : null,
            ID_Last_Page: applicant_data.id_last_page
              ? {
                  name: applicant_data.id_last_page?.split("/").pop(),
                  value: applicant_data.id_last_page,
                }
              : null,
            address_proof: applicant_data.address_proof
              ? {
                  name: applicant_data.address_proof?.split("/").pop(),
                  value: applicant_data.address_proof,
                }
              : null,
            no_of_shares: null,
            id_issue_date: applicant_data.id_issue_date,
            id_expiry_date: applicant_data.id_expiry_date,
            id_issue_country: applicant_data.id_issue_country,
            biz_user_id: applicant_data.biz_user_id,
          };
          this.backPageUrl = applicant_data.id_last_page
            ? applicant_data.id_last_page
            : null;
          this.frontPageUrl = applicant_data.id_first_page
            ? applicant_data.id_first_page
            : null;
          this.addressProofUrl = applicant_data.address_proof
            ? applicant_data.address_proof
            : null;
        })

        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },

    async getOccupation() {
      await this.$store.dispatch("Transactional/getOccupation");
      console.log("Occupation", this.occupation_list.occupation.data);
      // console.log(`responseData: ${JSON.stringify(this.occupation_list.occupation.data[0])}`);
      if (Array.isArray(this.occupation_list.occupation.data)) {
        this.occupation_options =
          await this.occupation_list.occupation.data.map((i) => {
            return {
              name: i.value,
              id: i.value_code,
            };
          });

        this.occupation_list.occupation.data.map((i) => {
          this.occupation_objs.push(i);
        });

        console.log("this", this.editedItem.occupation_code);
      }
    },
    async onFileChange(e) {
      console.log(e);
      const files = e.target.files || e.dataTransfer.files;

      this.IDfileName = files[0].name;
      const idpreviewImage = await this.getBase64(files[0]);
      this.idpreviewImage = idpreviewImage;
      const extractBase64 = idpreviewImage.split(",").reverse()[0];
      this.user_details.fileUpload = extractBase64;

      let fileData = new FormData();
      fileData.append("document", files[0]);
      console.log(fileData.document);
      try {
        console.log("inside of the try ");
        const responseData = await this.$store.dispatch(
          "Authentication/ekycDocSubmit", //flag for upload
          fileData
        );
        if (responseData.status === 200) {
          console.log("test uploaded");
        } else {
          this.errorMessage = responseData.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
        }
      } catch (error) {
        this.dismissCountDownError = this.dismissSecs;
        this.loader = false;
        this.errorMessage = "Server API is not working Please wait sometime";
      }
    },
    async fileUpload(fileObject, biz_user_id, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const token = sessionStorage.getItem("access_token");
      let fileData = new FormData();
      fileData.append("document", compressdata);
      try {
        const responseData = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/applicant-information/upload-docs?biz_user_id=${biz_user_id}&doc_type=${doc_type}`,
          data: fileData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          // params: { doc_type },
        });

        if (responseData && responseData.data.status_code == 200) {
          console.log("responseData", responseData);
          this.loader = false;
          this.getapplicant();
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async fileaddressProofUpload(fileObject, biz_user_id, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const token = sessionStorage.getItem("access_token");
      let fileData = new FormData();
      fileData.append("document", compressdata);
      try {
        const responseData = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/upload-address-proof?biz_user_id=${biz_user_id}&doc_type=${doc_type}`,
          data: fileData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (responseData && responseData.data.status_code == 200) {
          console.log("responseData", responseData);
          this.loader = false;
          this.getapplicant();
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async getidtypes() {
      let response = await this.$store.dispatch("Transactional/getIDtypes");
      response.data.data.map((idtype) => {
        this.idtypes.push(idtype.value);
        this.idtypes_val.push({
          name: idtype.value,
          id: idtype.value_code,
        });
        this.idtype_objs.push(idtype);
      });
      console.log("typein", this.idtypes);
    },

    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    openIdlast() {
      this.$refs.idlastfile.$el.querySelector("input").click();
    },
    openAddressProof() {
      this.$refs.addressProof.$el.querySelector("input").click();
    },
    openIdfirst() {
      this.$refs.idfirstfile.$el.querySelector("input").click();
    },
    ValidateAge(value) {
      if (!value) this.isDobError = false;
      else this.isDobError = !(value <= this.maxDate && value >= this.minDate);
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },

    async getCountryList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        // this.onChangeCounty('MYS');
      });
      console.log("country_options", this.country_options);
    },
    async getRelationship() {
      await this.$store.dispatch("Transactional/getRelationship");
      if (Array.isArray(this.realtion_list.response.data)) {
        this.relation_options = await this.realtion_list.response.data.map(
          (i) => i.value
        );
      }
    },

    async getNationalityList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("nationality,", responseData);
      responseData.data.map((i) => {
        this.nationality_options.push({
          name: i.nationality,
          id: i.iso_alpha3,
        });
      });
    },

    editItem(item) {
      this.editedIndex = this.getinformation.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.applicant_type = item.type;
      console.log("image data", item.type);
      this.idtype_objs.map((ele) => {
        console.log("ele", ele);
        if (ele.value == item.id_type_code) {
          this.editedItem.id_type_code = ele.value_code;
        }
      });
      this.editedItem.ID_First_Page = item.first_page
        ? {
            name: item.first_page?.split("/").pop(),
            value: item.first_page,
          }
        : null;

      this.editedItem.ID_Last_Page = item.last_page
        ? {
            name: item.last_page?.split("/").pop(),
            value: item.last_page,
          }
        : null;
      this.backPageUrl = item.last_page ? item.last_page : null;
      this.frontPageUrl = item.first_page ? item.first_page : null;
      this.editedItem.occupation_code = item.occupation_code
        ? item.occupation_code
        : null;
      // this.getapplicant();
      this.dialog = true;
    },

    onClickNext() {
      console.log(`editedItem: ${JSON.stringify(this.editedItem)}`);
      if (this.$refs.busApplicant.validate()) {
        this.save();
      }
    },

    onClickBack() {
      this.$emit("onClickBack");
    },

    async save() {
      this.loader = true;
      if (this.editedIndex > -1) {
        Object.assign(this.getinformation[this.editedIndex], this.editedItem);
      }
      try {
        const responseData = await axios.post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-applicant-information`,
          this.editedItem,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );

        console.log("responseData", responseData);

        if (
          responseData &&
          responseData.data &&
          responseData.data.success === true
        ) {
          this.userId = responseData.data.data[0].biz_user_id;
          console.log(" this.userId", this.userId);
          this.loader = false;
          console.log("ll", responseData);
          this.alertMsg.status = true;
          this.alertMsg.text = responseData.data.message;
          this.alertMsg.type = "success";
          this.alertMsg.color = "green";
          this.fileaddressProofUpload(
            this.editedItem.address_proof,
            responseData.data.data[0].biz_user_id,
            "ID_First_Page"
          );
          this.fileUpload(
            this.editedItem.ID_First_Page,
            responseData.data.data[0].biz_user_id,
            "ID_First_Page"
          );
          // let ID_Last_Page = new FormData();
          // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
          this.fileUpload(
            this.editedItem.ID_Last_Page,
            responseData.data.data[0].biz_user_id,
            "ID_Last_Page"
          );
          this.getapplicant();
        } else {
          this.loader = false;
          this.alertMsg.status = true;
          this.alertMsg.text = `Creation Failed: ${responseData.data.message}`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "warning";
          // alert("iam king")
        }
      } catch (error) {
        this.loader = false;
        this.alertMsg.status = true;
        this.alertMsg.text = `Creation Failed: please try again sometime`;
        this.alertMsg.type = "error";
        this.alertMsg.color = "error";
        // alert("jj")
      }
    },

    update() {
      if (this.$refs.busApplicant.validate()) {
        this.updateData();
      }
    },

    updateData() {
      this.loader = true;
      axios
        .put(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/update-applicant-information?biz_user_id=${this.editedItem.biz_user_id}`,
          this.editedItem,

          {
            headers: {
              ContentType: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        )
        .then((responseData) => {
          console.log(`responseData: ${JSON.stringify(responseData)}`);
          if (responseData && responseData.data.status_code == 200) {
            this.alertMsg.status = true;
            this.alertMsg.text = responseData.data.message;
            this.alertMsg.type = "success";
            this.alertMsg.color = "green";
            this.fileaddressProofUpload(
              this.editedItem.address_proof,
              responseData.data.data[0].biz_user_id,
              "ID_First_Page"
            );
            this.fileUpload(
              this.editedItem.ID_First_Page,
              responseData.data.data[0].biz_user_id,
              "ID_First_Page"
            );
            // let ID_Last_Page = new FormData();
            // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
            this.fileUpload(
              this.editedItem.ID_Last_Page,
              responseData.data.data[0].biz_user_id,
              "ID_Last_Page"
            );

            this.getapplicant();
          } else {
            this.alertMsg.status = true;
            this.alertMsg.text = `Update Failed: ${responseData.data.message}`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "warning";
          }
          console.log("respose data", responseData);
        })
        .catch((error) => {
          console.log(error.response);
          this.alertMsg.status = true;
          this.alertMsg.text = `Update Failed: please try again sometime`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "error";
        });
      setTimeout(() => {
        this.loader = false;
      }, 300);
    },
  },
};
</script>
<style>
.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
